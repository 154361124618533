import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { motion, useMotionValue, useTransform, animate } from "framer-motion"
import { useEffect } from "react"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function GradientBorder(props) {
    const { shineColor, borderColor, shineRange } = props
    const startAngle = props.randomize ? 0 : props.startAngle
    const inset = props.borderWidth

    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const angle = useMotionValue(
        !isCanvas && props.randomize ? Math.random() * 360 : 0
    )

    useEffect(() => {
        if (!isCanvas) {
            animate(
                angle,
                angle.get() + (props.direction == "ccw" ? -360 : 360),
                {
                    duration: props.duration,
                    repeat: Infinity,
                    ease: "linear",
                }
            )
        }
    }, [])

    const gradientStops =
        props.shineStyle == "sharp"
            ? props.direction == "ccw"
                ? `${shineColor} 0, ${borderColor} ${shineRange}deg`
                : `${borderColor} ${360 - shineRange}deg, ${shineColor} 360deg`
            : `${shineColor} 0, ${borderColor} ${
                  shineRange / 2
              }deg, ${borderColor} ${
                  360 - shineRange / 2
              }deg,${shineColor} 360deg`

    return (
        <motion.div
            style={{
                backgroundImage: isCanvas
                    ? `conic-gradient(from ${startAngle}deg, ${gradientStops})`
                    : useTransform(
                          [angle],
                          ([value]) =>
                              `conic-gradient(from ${
                                  startAngle + value
                              }deg, ${gradientStops})`
                      ),
                borderRadius: props.radiusIsMixed
                    ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
                    : `${props.radius}px`,
                boxShadow: props.shadows,
                ...props.style,
            }}
        >
            <div
                style={{
                    position: "absolute",
                    inset: inset,
                    backgroundColor: props.fill,
                    borderRadius: props.radiusIsMixed
                        ? `${props.radiusTopLeft - inset}px ${
                              props.radiusTopRight - inset
                          }px ${props.radiusBottomRight - inset}px ${
                              props.radiusBottomLeft - inset
                          }px`
                        : `${props.radius - inset}px`,
                }}
            />
        </motion.div>
    )
}

GradientBorder.displayName = "Animated Gradient Border"

addPropertyControls(GradientBorder, {
    shineStyle: {
        type: ControlType.Enum,
        defaultValue: "sharp",
        options: ["smooth", "sharp"],
        optionTitles: ["Smooth", "Sharp"],
        displaySegmentedControl: true,
        title: "Style",
    },
    borderWidth: {
        type: ControlType.Number,
        defaultValue: 4,
        min: 1,
        step: 1,
        title: "Width",
    },
    shineRange: {
        type: ControlType.Number,
        defaultValue: 45,
        min: 1,
        max: 180,
        step: 1,
        unit: "°",
    },
    shineColor: {
        type: ControlType.Color,
        defaultValue: "#6019FA",
        title: "Shine",
    },
    borderColor: {
        type: ControlType.Color,
        defaultValue: "#EDEDED",
        optional: true,
        title: "Border",
    },
    fill: {
        type: ControlType.Color,
        defaultValue: "#FFF",
    },
    duration: {
        type: ControlType.Number,
        defaultValue: 5,
        min: 0.1,
        description: "Seconds to rotate 360°",
    },
    direction: {
        type: ControlType.Enum,
        defaultValue: "cw",
        options: ["cw", "ccw"],
        optionTitles: ["Clockwise", "Counterclockwise"],
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
    },
    randomize: {
        type: ControlType.Boolean,
        defaultValue: true,
        title: "Random Start Angle",
    },
    startAngle: {
        type: ControlType.Number,
        defaultValue: 0,
        min: -360,
        max: 360,
        unit: "°",
        hidden: (props) => props.randomize,
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 20,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    shadows: {
        type: ControlType.BoxShadow,
    },
})
